























































import { Component, Vue } from 'vue-property-decorator';
import UploadFile from '@c/upload/UploadFile.vue';
import FileEcho from '@c/upload/FileEcho.vue';
import { InstitutionChangeSave, InstitutionChangeGet, InstitutionModuleGet } from '@/http/api/InstitutionApi';


@Component({
  components: {
    UploadFile,
    FileEcho,
  },
})
export default class ChangeChange extends Vue {
  private loading: boolean = false;
  private pageLoading: boolean = false;
  private requestId: string = '';
  private status: number = -1;
  private attFileList: any[] = [];
  private attFiles1: any = '';
  private attFiles2: any = '';
  private attFiles3: any = '';
  private fileEcho1: any[] = [];
  private fileEcho2: any[] = [];
  private uploadFileConfig1: any = {
    limit: 1,
    callback: [],
  };
  private uploadFileConfig2: any = {
    limit: 1,
    callback: [],
  };
  private uploadFileConfig3: any = {
    limit: 1,
    callback: [],
  };
  private created() {
    this.getStatus();
    this.getModel();
  }
  // 获取模板
  private getModel(): void {
    InstitutionModuleGet({ module: 4, type: 1 }).then((res) => {
      if (res.code === '000') {
        if (res.result && res.result.length > 0) {
          this.attFileList.push(res.result[0].fileInfo);
        }
      }
    });
  }
  // 获取状态
  private getStatus(): void {
    this.pageLoading = true;
    InstitutionChangeGet({ id: 3 }).then((res) => {
      this.pageLoading = false;
      if (res.code === '000') {
        if (res.result) {
          this.requestId = res.result.id;
          this.status = res.result.status;
          const file1Info: any = [
            {
              fileName: res.result.file1Info.fileName,
              id: res.result.file1Info.id,
              path: res.result.file1Info.path,
              remark: res.result.file1Info.remark,
            },
          ];
          const file2Info: any = [];
          if (res.result.file2Info) {
            file2Info.push({
              fileName: res.result.file2Info.fileName,
              id: res.result.file2Info.id,
              path: res.result.file2Info.path,
              remark: res.result.file2Info.remark,
            });
            this.attFiles2 = res.result.file2Info.path;
          }
          this.fileEcho1 = file1Info;
          this.uploadFileConfig1.callback = file1Info;
          this.attFiles1 = res.result.file1Info.path;
          this.fileEcho2 = file2Info;
          this.uploadFileConfig2.callback = file2Info;
        } else {
          this.requestId = '';
          this.status = 0;
        }
      }
    });
  }
  private articleSubmit(): void {
    const pames: any = {
      applyType: 3,
      id: this.requestId,
    };
    if (this.status < 1 || this.status === 3) {
      if (!this.attFiles1) {
        this.$message.warning('请上传机构调整资料');
        return;
      }
      pames.file1 = this.attFiles1;
    }
    if (this.status === 4 || this.status === 7) {
      if (!this.attFiles2) {
        this.$message.warning('请上传会议召开申请资料');
        return;
      }
      pames.file2 = this.attFiles2;
    }
    if (this.status === 8) {
      if (!this.attFiles3) {
        this.$message.warning('请上传会议召开申请资料');
        return;
      }
      pames.file3 = this.attFiles3;
    }
    this.loading = true;
    InstitutionChangeSave(pames).then((res) => {
      this.loading = false;
      if (res.code === '000') {
        this.$message.success('上传成功');
        if (this.status < 1 || this.status === 3) {
          this.status = 1;
        }
        if (this.status === 4 || this.status === 7) {
          this.status = 5;
        }
        if (this.status === 8) {
          this.$router.push({
            path: '/institution/success',
            query: {
              type: '1',
            },
          });
        }
      }
    });
  }
  private UploadFileFun1(val: any) {
    this.attFiles1 = val;
  }
  private UploadFileFun2(val: any) {
    this.attFiles2 = val;
  }
  private UploadFileFun3(val: any) {
    this.attFiles3 = val;
  }
}
